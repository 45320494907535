// import ClientJS from 'clientjs';
import { SagaIterator } from 'redux-saga';
import { put, call, select, takeEvery } from 'redux-saga/effects';

import * as api from './analytics-api';
import {
  types,
  sendAnalyticsRequest,
  sendAnalyticsSuccess,
  sendAnalyticsFailure,
  SendAnalyticsAction,
} from './actions';
import { RootState } from '..';

let client = undefined;

const clientDetails = () => {
  if (client === undefined) {
    console.log('Creating a client');

    const ClientJS = require('clientjs');
    client = new ClientJS();
  }

  const { browser, cpu, device, ua, os, engine } = client.getResult();

  const _device = {
    osName: os.name,
    osVersion: os.version,
    cpuArch: cpu.architecture,
    deviceType: device.type,
    deviceModel: device.model,
    deviceVendor: device.vendor,
  };

  const _browser = {
    userAgent: ua,
    browserName: browser.name,
    browserVersion: browser.version,
    browserEngine: engine.name,
    screenSize: `${window.screen.width}x${window.screen.height}`,
  };

  return {
    device: _device,
    browser: _browser,
  };
};

function* sendAnalytics(action: SendAnalyticsAction): SagaIterator {
  if (typeof window === 'undefined') {
    // return if on the server
    return;
  }

  yield put(sendAnalyticsRequest());

  try {
    const { userId, sessionId } = yield select<(state: RootState) => any>((state) => state.auth);

    const data = {
      ...action.data,
      userId,
      sessionId,
      meta: {
        ...action.data.meta,
        ...clientDetails(),
      },
    };

    yield call(api.sendAnalytics, data);

    yield put(sendAnalyticsSuccess());
  } catch (error) {
    yield put(sendAnalyticsFailure());
  }
}

export default function* watchAnalyticsSaga() {
  yield takeEvery(types.SEND_ANALYTICS, sendAnalytics);
}
