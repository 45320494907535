import { HYDRATE } from 'next-redux-wrapper';
import { types, AuthAction } from './types';

const TEST_USERS = [
  '4b9a1caa-1cd6-4ccc-9806-371dd489db6e',
  'c47d6804-47d4-459c-9b8e-2803e95dca96',
  '9d18fa30-3329-41ef-8b53-6b75ff98d0a7',
  'e9a754dd-fb2e-48aa-8c0d-cdec99799ec5',
  '58ce0f70-2b5c-4042-a36d-468602f9e237',
  '180cc70e-98b7-4fe1-9e47-843ebabc0ea9',
  'c48c3869-c547-44a9-8649-71635a4ac949',
  '17ed0035-eec0-4e1a-98e7-ffc0c9284dc3',
  '318448f7-af16-49ed-b0b1-51b70a0254e2',
  'b7b0bda4-a7a3-49f2-9657-f6bd2c7d378e'
]


export interface AuthState
  extends Readonly<{
    userId: string;
    sessionId: string;
    authToken: string;
    isFetching: boolean;
    isFetchingOTP: boolean;
    errors: Record<string, string>[];
    isTestUser: boolean;
  }> { }

const initialState: AuthState = {
  isFetching: false,
  isFetchingOTP: false,
  errors: undefined,
  authToken: undefined,
  userId: undefined,
  sessionId: undefined,
  isTestUser: false
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case HYDRATE: {
      const { auth = {} } = action.payload;
      const userId = state.userId === undefined ? (auth as AuthState).userId : state.userId;
      const sessionId =
        state.sessionId === undefined ? (auth as AuthState).sessionId : state.sessionId;

      return {
        ...state,
        ...auth,
        userId,
        sessionId,
        isTestUser: TEST_USERS.includes(userId)
      };
    }
    case types.SAVE_SESSION_TOKEN:
      return {
        ...state,
        userId: action.userId,
        sessionId: action.sessionId,
        isTestUser: TEST_USERS.includes(action.userId)
      };
    case types.SIGNUP_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      };
    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errors: undefined,
      };
    }
    case types.SIGNUP_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.messages,
      };
    case types.NEW_OTP_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetchingOTP: true,
        errors: undefined,
        authToken: undefined,
      };
    case types.NEW_OTP_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingOTP: false,
        errors: undefined,
      };
    }
    case types.NEW_OTP_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchingOTP: false,
        errors: action.messages,
      };
    case types.VALIDATE_OTP_REQUEST:
      return {
        ...state,
        isFetching: true,
        authToken: undefined,
        errors: undefined,
      };
    case types.VALIDATE_OTP_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        authToken: action.authToken,
      };
    }
    case types.VALIDATE_OTP_FAILURE:
      return {
        ...state,
        isFetching: false,
        authToken: undefined,
        errors: action.messages,
      };
    case types.SIGNIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        authToken: undefined,
        errors: undefined,
      };
    case types.SIGNIN_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        authToken: action.authToken,
      };
    }
    case types.SIGNIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authToken: undefined,
        isTestUser: false,
        errors: action.messages,
      };
    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: undefined,
      };
    case types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        errors: undefined,
        authToken: undefined
      };
    }
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.messages,
      };
    case types.SIGNOUT_REQUEST:
      return {
        ...state,
        authToken: undefined,
        isTestUser: false
      };
    default:
      return state;
  }
};

export default authReducer;
