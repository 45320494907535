import { normalize } from 'normalizr';
import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from '../api';
import { usersModel } from '../schemas';
import { User, UserEntities } from './interfaces';

import { types, fetchUsersRequest, fetchUsersSuccess, fetchUsersFailure } from './actions';

function* fetchUsers(): SagaIterator {
  yield put(fetchUsersRequest());

  try {
    const { data } = yield call(api.getUsers);

    const normalized = normalize<User[], UserEntities, string[]>(data, usersModel);
    const { result: userIds, entities } = normalized;

    yield put(fetchUsersSuccess(entities.users, userIds));
  } catch (error) {
    yield put(fetchUsersFailure());
  }
}

export default function* watchUsers(): SagaIterator {
  yield takeEvery(types.FETCH_USERS, fetchUsers);
}
