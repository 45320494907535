import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as api from '../copyrights-api';

import {
  amendCopyrightRequest,
  amendCopyrightSuccess,
  amendCopyrightFailure,
  AmendCopyrightAction,
} from './actions';

export default function* amendCopyright(action: AmendCopyrightAction): SagaIterator {
  yield put(amendCopyrightRequest());

  try {
    yield call(api.amendCopyright, action.requestType, action.copyrightId);

    yield put(amendCopyrightSuccess());
  } catch (error) {
    yield put(amendCopyrightFailure(JSON.parse(error.message)));
  }
}
