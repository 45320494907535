import { HYDRATE } from 'next-redux-wrapper';

import { mergeIds, mergeObjects } from '../utils';
import { types, CategoryActions } from './actions';
import { Categories, SubCategories } from './interfaces';

import { HydrateAction } from '../index';

export interface CategoriesState
  extends Readonly<{
    categories: Categories;
    categoryIds: string[];
    subCategories: SubCategories;
    isFetching: boolean;
  }> {}

const initialState: CategoriesState = {
  categories: {},
  categoryIds: [],
  subCategories: {},
  isFetching: false,
};

const usersReducer = (
  state = initialState,
  action: CategoryActions | HydrateAction,
): CategoriesState => {
  switch (action.type) {
    case HYDRATE: {
      const { categories = {} } = action.payload;

      return {
        ...state,
        ...categories,
      };
    }
    case types.FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CATEGORIES_SUCCESS: {
      const { categoryIds, categories } = action;

      return {
        ...state,
        isFetching: false,
        categoryIds: mergeIds<string>(state.categoryIds, categoryIds),
        subCategories: {
          ...state.subCategories,
          ...action.subCategories,
        },
        categories: mergeObjects<Categories, string>(categoryIds, state.categories, categories),
      };
    }
    case types.FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
