import { types } from '../types';
import { ValidateOTPInputs } from '../interfaces';

export interface ValidateOTPRequest {
  type: typeof types.VALIDATE_OTP_REQUEST;
}

export interface ValidateOTPSuccess {
  type: typeof types.VALIDATE_OTP_SUCCESS;
  authToken: string;
}

export interface ValidateOTPFailure {
  type: typeof types.VALIDATE_OTP_FAILURE;
  messages: Record<string, string>[];
}

type ValidateOTPMeta = {
  phoneNumber: string;
  resetPassword: boolean;
};

export interface ValidateOTPAction {
  type: typeof types.VALIDATE_OTP;
  payload: ValidateOTPInputs;
  meta?: ValidateOTPMeta;
}

export const validateOTPRequest = () => ({
  type: types.VALIDATE_OTP_REQUEST,
});

export const validateOTPSuccess = (authToken: string) => ({
  type: types.VALIDATE_OTP_SUCCESS,
  authToken,
});

export const validateOTPFailure = (messages: Record<string, string>[]) => ({
  type: types.VALIDATE_OTP_FAILURE,
  messages,
});

export const validateOTP = (
  payload: ValidateOTPInputs,
  meta?: ValidateOTPMeta,
): ValidateOTPAction => {
  return {
    type: types.VALIDATE_OTP,
    payload,
    meta,
  };
};

export type ValidateOTPActions =
  | ValidateOTPRequest
  | ValidateOTPSuccess
  | ValidateOTPFailure
  | ValidateOTPAction;
