import { types } from '../types';
import { Copyrights } from '../interfaces';

export interface FetchHistoricalCopyrightsRequest {
  type: typeof types.FETCH_HISTORICAL_COPYRIGHTS_REQUEST;
}

export interface FetchHistoricalCopyrightsSuccess {
  type: typeof types.FETCH_HISTORICAL_COPYRIGHTS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export interface FetchHistoricalCopyrightsFailure {
  type: typeof types.FETCH_HISTORICAL_COPYRIGHTS_FAILURE;
}

export interface FetchHistoricalCopyrightsAction {
  type: typeof types.FETCH_HISTORICAL_COPYRIGHTS;
}

export const fetchHistoricalCopyrightsRequest = () => ({
  type: types.FETCH_HISTORICAL_COPYRIGHTS_REQUEST,
});

export const fetchHistoricalCopyrightsSuccess = (
  copyrights: Copyrights,
  copyrightIds: string[],
) => ({
  type: types.FETCH_HISTORICAL_COPYRIGHTS_SUCCESS,
  copyrights,
  copyrightIds,
});

export const fetchHistoricalCopyrightsFailure = () => ({
  type: types.FETCH_HISTORICAL_COPYRIGHTS_FAILURE,
});

export const fetchHistoricalCopyrights = (): FetchHistoricalCopyrightsAction => {
  return {
    type: types.FETCH_HISTORICAL_COPYRIGHTS,
  };
};

export interface FetchHistoricalCopyrightDetails {
  type: typeof types.FETCH_HISTORICAL_COPYRIGHT_DETAILS;
  copyrightId: string;
}

export interface FetchHistoricalCopyrightDetailsSuccess {
  type: typeof types.FETCH_HISTORICAL_COPYRIGHT_DETAILS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export const fetchHistoricalCopyrightDetails = (copyrightId: string) => ({
  type: types.FETCH_HISTORICAL_COPYRIGHT_DETAILS,
  copyrightId,
});

export const fetchHistoricalCopyrightDetailsSuccess = (
  copyrights: Copyrights,
  copyrightIds: string[],
) => ({
  type: types.FETCH_HISTORICAL_COPYRIGHT_DETAILS_SUCCESS,
  copyrights,
  copyrightIds,
});

export interface HistoricalCopyrightUpdated {
  type: typeof types.HISTORICAL_COPYRIGHT_UPDATED;
  draftId: string;
}

export const historicalCopyrightUpdated = (draftId: string) => ({
  type: types.HISTORICAL_COPYRIGHT_UPDATED,
  draftId,
});

export type HistoricalCopyrightsActions =
  | FetchHistoricalCopyrightsRequest
  | FetchHistoricalCopyrightsFailure
  | FetchHistoricalCopyrightsSuccess
  | FetchHistoricalCopyrightDetails
  | FetchHistoricalCopyrightDetailsSuccess
  | HistoricalCopyrightUpdated;
