import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as authApi from '../auth-api';
import { formartPhoneNo } from '../../../lib/utils';
import { AuthService } from '../../../lib/services/auth-service';
import { newOTPRequest, newOTPSuccess, newOTPFailure, NewOTPAction } from './actions';
import { sendAnalytics } from '../../analytics/actions';

export default function* newOTP(action: NewOTPAction): SagaIterator {
  yield put(newOTPRequest());
  yield put(
    sendAnalytics({
      event: 'newOTPRequest',
      category: 'Auth',
      meta: {
        phoneNo: action.phoneNumber,
      },
    }),
  );

  try {
    const phoneNumber = formartPhoneNo(action.phoneNumber);
    const { userId } = yield call(authApi.newOTP, phoneNumber, action.validate);

    yield put(newOTPSuccess());

    yield put(
      sendAnalytics({
        event: 'newOTPSuccess',
        category: 'Auth',
        meta: {},
      }),
    );

    if (action.redirect)
      yield call(AuthService.verifyPhoneNo, userId, phoneNumber, '/reset-password');
  } catch (error) {
    yield put(newOTPFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'newOTPFailure',
        category: 'Auth',
        meta: {
          phoneNo: action.phoneNumber,
          error: JSON.parse(error.message),
        },
      }),
    );
  }
}
