export const mergeIds = <K>(stateIds: K[], newIds: K[], newItem = false): K[] => {
  let newStateIds = [...stateIds];

  if (newItem) {
    newStateIds = [...newIds, ...stateIds];
  } else {
    newIds.map((id) => {
      newStateIds.indexOf(id) > -1 ? null : newStateIds.push(id);
    });
  }

  return newStateIds;
};

export const mergeObjects = <T, K extends keyof T>(itemIds: K[], oldItems: T, newItems: T): T => {
  const newState = { ...oldItems };

  itemIds.map((id) => {
    newState[id] = {
      ...(oldItems[id] || {}),
      ...newItems[id],
    };
  });

  return newState;
};
