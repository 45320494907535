import { HydrateAction } from '../index';
import { Invoice, Invoices, Services } from './interfaces';

export enum types {
  ADD_TO_INVOICE = '@@transactions/ADD_TO_INVOICE',

  GENERATE_INVOICE = '@@transactions/GENERATE_INVOICE',
  GENERATE_INVOICE_REQUEST = '@@transactions/GENERATE_INVOICE_REQUEST',
  GENERATE_INVOICE_SUCCESS = '@@transactions/GENERATE_INVOICE_SUCCESS',
  GENERATE_INVOICE_FAILURE = '@@transactions/GENERATE_INVOICE_FAILURE',

  FETCH_PENDING_INVOICES = '@@transactions/FETCH_PENDING_INVOICES',
  FETCH_PENDING_INVOICES_REQUEST = '@@transactions/FETCH_PENDING_INVOICES_REQUEST',
  FETCH_PENDING_INVOICES_SUCCESS = '@@transactions/FETCH_PENDING_INVOICES_SUCCESS',
  FETCH_PENDING_INVOICES_FAILURE = '@@transactions/FETCH_PENDING_INVOICES_FAILURE',

  FETCH_SERVICES = '@@transactions/FETCH_SERVICES',
  FETCH_SERVICES_REQUEST = '@@transactions/FETCH_SERVICES_REQUEST',
  FETCH_SERVICES_SUCCESS = '@@transactions/FETCH_SERVICES_SUCCESS',
  FETCH_SERVICES_FAILURE = '@@transactions/FETCH_SERVICES_FAILURE',
}

export interface GenerateInvoiceRequest {
  type: typeof types.GENERATE_INVOICE_REQUEST;
}

export interface GenerateInvoiceSuccess {
  type: typeof types.GENERATE_INVOICE_SUCCESS;
  invoice: Invoice;
}

export interface GenerateInvoiceFailure {
  type: typeof types.GENERATE_INVOICE_FAILURE;
}

export interface GenerateInvoiceAction {
  type: typeof types.GENERATE_INVOICE;
}

export interface AddToInvoiceAction {
  type: typeof types.ADD_TO_INVOICE;
  copyrightId: string;
}

export const generateInvoiceRequest = () => ({
  type: types.GENERATE_INVOICE_REQUEST,
});

export const generateInvoiceSuccess = (invoice: Invoice) => ({
  type: types.GENERATE_INVOICE_SUCCESS,
  invoice,
});

export const generateInvoiceFailure = () => ({
  type: types.GENERATE_INVOICE_FAILURE,
});

export const generateInvoice = (): GenerateInvoiceAction => ({
  type: types.GENERATE_INVOICE,
});

export const addToInvoice = (copyrightId: string): AddToInvoiceAction => ({
  type: types.ADD_TO_INVOICE,
  copyrightId,
});

/* FETCH INVOICES */
export interface FetchPendingInvoicesRequest {
  type: typeof types.FETCH_PENDING_INVOICES_REQUEST;
}

export interface FetchPendingInvoicesFailure {
  type: typeof types.FETCH_PENDING_INVOICES_FAILURE;
}

export interface FetchPendingInvoicesSuccess {
  type: typeof types.FETCH_PENDING_INVOICES_SUCCESS;
  invoiceIds: string[];
  invoices: Invoices;
}

export interface FetchPendingInvoicesAction {
  type: typeof types.FETCH_PENDING_INVOICES;
}

export const fetchPendingInvoicesRequest = () => ({
  type: types.FETCH_PENDING_INVOICES_REQUEST,
});

export const fetchPendingInvoicesFailure = () => ({
  type: types.FETCH_PENDING_INVOICES_FAILURE,
});

export const fetchPendingInvoicesSuccess = (invoices: Invoices, invoiceIds: string[]) => ({
  type: types.FETCH_PENDING_INVOICES_SUCCESS,
  invoices,
  invoiceIds,
});

export const fetchPendingInvoices = (): FetchPendingInvoicesAction => {
  return {
    type: types.FETCH_PENDING_INVOICES,
  };
};

/* FETCH SERVICES */
export interface FetchServicesRequest {
  type: typeof types.FETCH_SERVICES_REQUEST;
}

export interface FetchServicesFailure {
  type: typeof types.FETCH_SERVICES_FAILURE;
}

export interface FetchServicesSuccess {
  type: typeof types.FETCH_SERVICES_SUCCESS;
  serviceIds: string[];
  services: Services;
}

export interface FetchServicesAction {
  type: typeof types.FETCH_SERVICES;
}

export const fetchServicesRequest = () => ({
  type: types.FETCH_SERVICES_REQUEST,
});

export const fetchServicesFailure = () => ({
  type: types.FETCH_SERVICES_FAILURE,
});

export const fetchServicesSuccess = (services: Services, serviceIds: string[]) => ({
  type: types.FETCH_SERVICES_SUCCESS,
  services,
  serviceIds,
});

export const fetchServices = (): FetchServicesAction => {
  return {
    type: types.FETCH_SERVICES,
  };
};

export type TransactionsActions =
  | HydrateAction
  | GenerateInvoiceRequest
  | GenerateInvoiceSuccess
  | GenerateInvoiceFailure
  | GenerateInvoiceAction
  | AddToInvoiceAction
  | FetchPendingInvoicesRequest
  | FetchPendingInvoicesFailure
  | FetchPendingInvoicesSuccess
  | FetchServicesAction
  | FetchServicesRequest
  | FetchServicesFailure
  | FetchServicesSuccess;
