import { Users } from './interfaces';

export enum types {
  FETCH_USERS = '@@users/FETCH_USERS',
  FETCH_USERS_REQUEST = '@@users/FETCH_REQUEST',
  FETCH_USERS_SUCCESS = '@@users/FETCH_SUCCESS',
  FETCH_USERS_FAILURE = '@@users/FETCH_FAILURE',
}

export interface FetchUsersRequest {
  type: typeof types.FETCH_USERS_REQUEST;
}

export interface FetchUsersSuccess {
  type: typeof types.FETCH_USERS_SUCCESS;
  users: Users;
  userIds: string[];
}

export interface FetchUsersFailure {
  type: typeof types.FETCH_USERS_FAILURE;
}

export interface FetchUsers {
  type: typeof types.FETCH_USERS;
}

export type UsersAction = FetchUsersRequest | FetchUsersSuccess | FetchUsersFailure | FetchUsers;

export const fetchUsersRequest = () => ({
  type: types.FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (users: Users, userIds: string[]) => ({
  type: types.FETCH_USERS_SUCCESS,
  users,
  userIds,
});

export const fetchUsersFailure = () => ({
  type: types.FETCH_USERS_FAILURE,
});

export const fetchUsers = () => ({
  type: types.FETCH_USERS,
});
