import { types } from '../types';
import {
  SignupResponse as User,
  SignupIndividualInputs,
  SignupCorporateInputs,
} from '../interfaces';

interface SignupRequest {
  type: typeof types.SIGNUP_REQUEST;
}

interface SignupSuccess {
  type: typeof types.SIGNUP_SUCCESS;
  user: User;
}

interface SignupFailure {
  type: typeof types.SIGNUP_FAILURE;
  messages: Record<string, string>[];
}

export interface SignupIndividualAction {
  type: typeof types.SIGNUP_INDIVIDUAL;
  data: SignupIndividualInputs;
}

export interface SignupCorporateAction {
  type: typeof types.SIGNUP_CORPORATE;
  data: SignupCorporateInputs;
}

export const signupRequest = () => ({
  type: types.SIGNUP_REQUEST,
});

export const signupSuccess = (user: User) => ({
  type: types.SIGNUP_SUCCESS,
  user,
});

export const signupFailure = (messages: Record<string, string>[]) => ({
  type: types.SIGNUP_FAILURE,
  messages,
});

export const signupIndividual = (data: SignupIndividualInputs): SignupIndividualAction => {
  return {
    type: types.SIGNUP_INDIVIDUAL,
    data,
  };
};

export const signupCorporate = (data: SignupCorporateInputs): SignupCorporateAction => {
  return {
    type: types.SIGNUP_CORPORATE,
    data,
  };
};

export type SignupActions =
  | SignupRequest
  | SignupSuccess
  | SignupFailure
  | SignupIndividualAction
  | SignupCorporateAction;
