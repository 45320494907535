import { normalize } from 'normalizr';
import { SagaIterator } from 'redux-saga';
import { put, call, select, takeEvery } from 'redux-saga/effects';

import * as api from './transactions-api';
import { invoicesModel, servicesModel } from '../schemas';
import {
  types,
  generateInvoiceRequest,
  generateInvoiceSuccess,
  generateInvoiceFailure,
  fetchPendingInvoicesSuccess,
  fetchPendingInvoicesRequest,
  fetchPendingInvoicesFailure,
  fetchServicesSuccess,
  fetchServicesRequest,
  fetchServicesFailure,
} from './actions';
import { fetchPendingCopyrights } from '../copyrights/fetch-copyrights/actions';
import { RootState } from '..';
import { Invoice, InvoiceEntities, Service, ServiceEntities } from './interfaces';

const normalizeResponse = (invoices: Invoice[]) => {
  return normalize<Invoice[], InvoiceEntities, string[]>(invoices, invoicesModel);
};

function* generateInvoice(): SagaIterator {
  yield put(generateInvoiceRequest());

  try {
    const { copyrightIds = [] } = yield select<(state: RootState) => any>(
      (state) => state.transactions,
    );

    const data = {
      copyrightIds,
    };

    const copyright = yield call(api.generateInvoice, data);
    const { result: invoiceIds, entities } = normalizeResponse([copyright]);
    const invoice = entities.invoices[invoiceIds[0]];

    yield put(generateInvoiceSuccess(invoice));
    yield put(fetchPendingCopyrights());
    yield put(fetchPendingInvoicesSuccess(entities.invoices, invoiceIds));
  } catch (error) {
    yield put(generateInvoiceFailure());
  }
}

function* fetchPendingInvoices(): SagaIterator {
  yield put(fetchPendingInvoicesRequest());

  try {
    const { data: invoices } = yield call(api.fetchPendingInvoices);

    const { result: invoiceIds, entities } = normalizeResponse(invoices);

    yield put(fetchPendingInvoicesSuccess(entities.invoices, invoiceIds));
  } catch (error) {
    yield put(fetchPendingInvoicesFailure());
  }
}

const normalizeServicesResponse = (services: Service[]) => {
  return normalize<Service[], ServiceEntities, string[]>(services, servicesModel);
};

function* fetchServices(): SagaIterator {
  yield put(fetchServicesRequest());

  try {
    const { data: services } = yield call(api.fetchServices);

    const { result: serviceIds, entities } = normalizeServicesResponse(services);

    yield put(fetchServicesSuccess(entities.services, serviceIds));
  } catch (error) {
    yield put(fetchServicesFailure());
  }
}

export default function* watchTransactionsSaga() {
  yield takeEvery(types.GENERATE_INVOICE, generateInvoice);
  yield takeEvery(types.FETCH_PENDING_INVOICES, fetchPendingInvoices);
  yield takeEvery(types.FETCH_SERVICES, fetchServices);
}
