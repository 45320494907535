import { types } from '../types';
import { NewCopyrightDetails, Copyright } from '../interfaces';

interface RegisterCopyrightRequest {
  type: typeof types.REGISTER_COPYRIGHT_REQUEST;
}

interface RegisterCopyrightSuccess {
  type: typeof types.REGISTER_COPYRIGHT_SUCCESS;
  copyright: any;
}

interface RegisterCopyrightFailure {
  type: typeof types.REGISTER_COPYRIGHT_FAILURE;
  messages: Record<string, string>[];
}

export interface RegisterCopyrightAction {
  type: typeof types.REGISTER_COPYRIGHT;
  data: NewCopyrightDetails;
  isDraft: boolean;
}

export const registerCopyrightRequest = () => ({
  type: types.REGISTER_COPYRIGHT_REQUEST,
});

export const registerCopyrightSuccess = (copyright: Copyright) => ({
  type: types.REGISTER_COPYRIGHT_SUCCESS,
  copyright,
});

export const registerCopyrightFailure = (messages: Record<string, string>[]) => ({
  type: types.REGISTER_COPYRIGHT_FAILURE,
  messages,
});

export const registerCopyright = (
  data: NewCopyrightDetails,
  isDraft = false,
): RegisterCopyrightAction => {
  return {
    type: types.REGISTER_COPYRIGHT,
    data,
    isDraft,
  };
};

export interface SaveAndContinueAction {
  type: typeof types.SAVE_AND_CONTINUE;
  payload: {
    draftData: NewCopyrightDetails;
    step: number;
  };
}

export const saveAndContinue = (
  draftData: NewCopyrightDetails,
  step: number,
): SaveAndContinueAction => {
  return {
    type: types.SAVE_AND_CONTINUE,
    payload: {
      draftData,
      step,
    },
  };
};

export type RegisterCopyrightActions =
  | RegisterCopyrightRequest
  | RegisterCopyrightSuccess
  | RegisterCopyrightFailure
  | RegisterCopyrightAction
  | SaveAndContinueAction;
