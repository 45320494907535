import { HYDRATE } from 'next-redux-wrapper';
import { mergeIds, mergeObjects } from '../utils';
import { types, CopyrightAction } from './types';
import { NewCopyrightDetails, Copyrights } from './interfaces';

export interface CopyrightsState
  extends Readonly<{
    isFetching: boolean;
    isSuccess: boolean;
    copyrightCreated: boolean;
    newCopyright: {
      step: number;
      copyrightId?: string;
      values: NewCopyrightDetails | {};
    };
    draftIds: string[];
    approvedIds: string[];
    searchedIds: string[];
    pendingIds: string[];
    rejectedIds: string[];
    copyrights: Copyrights;
    errors: Record<string, string>[];
    disputed: boolean;
    historicalCopyrightIds: string[];
    historicalCopyrights: Copyrights;
  }> {}

const initialState: CopyrightsState = {
  isFetching: false,
  isSuccess: false,
  errors: undefined,
  copyrightCreated: false,
  newCopyright: {
    step: 0,
    values: {},
    copyrightId: undefined,
  },
  copyrights: {},
  draftIds: [],
  pendingIds: [],
  approvedIds: [],
  rejectedIds: [],
  searchedIds: [],
  disputed: false,
  historicalCopyrights: {},
  historicalCopyrightIds: [],
};

const copyrightsReducer = (state = initialState, action: CopyrightAction): CopyrightsState => {
  switch (action.type) {
    case HYDRATE: {
      const { copyrights = {} } = action.payload;

      return {
        ...state,
        ...copyrights,
      };
    }
    case types.SAVE_AND_CONTINUE:
      return {
        ...state,
        copyrightCreated: false,
        errors: undefined,
        newCopyright: {
          step: action.payload.step,
          values: {
            ...state.newCopyright.values,
            ...action.payload.draftData,
          },
        },
      };
    case types.REGISTER_COPYRIGHT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: undefined,
        copyrightCreated: false,
      };
    case types.REGISTER_COPYRIGHT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        copyrightCreated: true,
        newCopyright: {
          ...state.newCopyright,
          copyrightId: action.copyright.id,
          step: 0,
          values: {
            ...state.newCopyright.values,
            workDetails: undefined,
            documents: [],
            authors: [],
            producers: [],
            publishers: [],
            performers: [],
            totalOwnership: 0,
          },
        },
      };
    }
    case types.REGISTER_COPYRIGHT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.messages,
        copyrightCreated: false,
      };
    case types.UPDATE_COPYRIGHT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.messages,
        copyrightCreated: false,
      };
    case types.UPDATE_COPYRIGHT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: undefined,
        copyrightCreated: false,
      };
    case types.UPDATE_COPYRIGHT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        copyrightCreated: true,
        newCopyright: {
          ...state.newCopyright,
          step: 0,
          values: {
            ...state.newCopyright.values,
            workDetails: undefined,
            applicants: [
              {
                email: '',
                firstName: '',
                idNumber: '',
                lastName: '',
                location: '',
                phoneNumber: '',
              },
            ],
            documents: [
              {
                title: '',
                fileName: '',
                fileType: '',
                documentUrl: '',
                documentArtifact: undefined,
              },
            ],
            authors: [
              {
                name: '',
                idNumber: '',
                stageName: '',
                phoneNumber: '',
                ownershipPerc: '',
              },
            ],
            producers: [
              {
                name: '',
                idNumber: '',
                phoneNumber: '',
                ownershipPerc: '',
              },
            ],
            publishers: [{ name: '', idNumber: '', phoneNumber: '', ownershipPerc: '' }],
            performers: [{ name: '', idNumber: '', phoneNumber: '', ownershipPerc: '', role: '' }],
            totalOwnership: 0,
          },
        },
      };
    }
    case types.FETCH_COPYRIGHTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_APPROVED_COPYRIGHTS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        approvedIds: mergeIds<string>(state.approvedIds, copyrightIds),
        copyrights: mergeObjects<Copyrights, string>(copyrightIds, state.copyrights, copyrights),
      };
    }
    case types.FETCH_PENDING_COPYRIGHTS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        pendingIds: mergeIds<string>(state.pendingIds, copyrightIds),
        copyrights: mergeObjects<Copyrights, string>(copyrightIds, state.copyrights, copyrights),
      };
    }
    case types.FETCH_DRAFT_COPYRIGHTS:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_DRAFT_COPYRIGHTS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        draftIds: mergeIds<string>(state.draftIds, copyrightIds),
        copyrights: mergeObjects<Copyrights, string>(copyrightIds, state.copyrights, copyrights),
      };
    }
    case types.FETCH_REJECTED_COPYRIGHTS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        rejectedIds: mergeIds<string>(state.rejectedIds, copyrightIds),
        copyrights: mergeObjects<Copyrights, string>(copyrightIds, state.copyrights, copyrights),
      };
    }
    case types.FETCH_COPYRIGHT_DETAILS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        copyrights: mergeObjects<Copyrights, string>(copyrightIds, state.copyrights, copyrights),
      };
    }
    case types.FETCH_COPYRIGHTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.SEARCH_COPYRIGHTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SEARCH_COPYRIGHTS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        // searchedIds: mergeIds<string>(state.searchedIds, copyrightIds),
        searchedIds: [...copyrightIds],
        copyrights: mergeObjects<Copyrights, string>(copyrightIds, state.copyrights, copyrights),
      };
    }
    case types.SEARCH_COPYRIGHTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.FETCH_HISTORICAL_COPYRIGHTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.HISTORICAL_COPYRIGHT_UPDATED: {
      return {
        ...state,
        historicalCopyrightIds: state.historicalCopyrightIds.filter((id) => {
          id !== action.draftId;
        }),
      };
    }
    case types.FETCH_HISTORICAL_COPYRIGHTS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        historicalCopyrightIds: [...copyrightIds],
        historicalCopyrights: mergeObjects<Copyrights, string>(
          copyrightIds,
          state.historicalCopyrights,
          copyrights,
        ),
      };
    }
    case types.FETCH_HISTORICAL_COPYRIGHTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.FETCH_HISTORICAL_COPYRIGHT_DETAILS_SUCCESS: {
      const { copyrightIds, copyrights } = action;

      return {
        ...state,
        isFetching: false,
        historicalCopyrightIds: mergeIds<string>(state.historicalCopyrightIds, copyrightIds),
        historicalCopyrights: mergeObjects<Copyrights, string>(
          copyrightIds,
          state.historicalCopyrights,
          copyrights,
        ),
      };
    }
    case types.DISPUTE_COPYRIGHT_REQUEST:
      return {
        ...state,
        isFetching: true,
        disputed: false,
      };
    case types.DISPUTE_COPYRIGHT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        disputed: true,
      };
    }
    case types.DISPUTE_COPYRIGHT_FAILURE:
      return {
        ...state,
        isFetching: false,
        disputed: false,
      };
    case types.AMEND_COPYRIGHT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isSuccess: false,
      };
    case types.AMEND_COPYRIGHT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
      };
    }
    case types.AMEND_COPYRIGHT_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        errors: action.messages,
      };
    default:
      return state;
  }
};

export default copyrightsReducer;
