import axios from 'axios';

export const BASE_URL = 'https://jsonplaceholder.typicode.com';

const createAxios = () => {
  const _axios = axios.create({
    baseURL: BASE_URL,
    headers: {},
  });

  return _axios;
};

const api = createAxios();

export const getUsers = () => {
  return api.get('/users');
};

export default api;
