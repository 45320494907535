import { types } from '../types';

export interface DisputeCopyrightRequest {
  type: typeof types.DISPUTE_COPYRIGHT_REQUEST;
}

export interface DisputeCopyrightFailure {
  type: typeof types.DISPUTE_COPYRIGHT_FAILURE;
}

export interface DisputeCopyrightSuccess {
  type: typeof types.DISPUTE_COPYRIGHT_SUCCESS;
}

export interface DisputeCopyrightAction {
  type: typeof types.DISPUTE_COPYRIGHT;
  notes: string;
  copyrightId: string;
}

export const disputeCopyrightRequest = () => ({
  type: types.DISPUTE_COPYRIGHT_REQUEST,
});

export const disputeCopyrightFailure = () => ({
  type: types.DISPUTE_COPYRIGHT_FAILURE,
});

export const disputeCopyrightSuccess = () => ({
  type: types.DISPUTE_COPYRIGHT_SUCCESS,
});

export const disputeCopyright = (notes: string, copyrightId: string): DisputeCopyrightAction => {
  return {
    type: types.DISPUTE_COPYRIGHT,
    notes,
    copyrightId,
  };
};

export type DisputeCopyrightActions =
  | DisputeCopyrightRequest
  | DisputeCopyrightFailure
  | DisputeCopyrightSuccess;
