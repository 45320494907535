import { HydrateAction } from '../index';
import { FetchCurrentUserAction } from './actions';

export type ProfileAction = HydrateAction | FetchCurrentUserAction;

export enum types {
  FETCH_CURRENT_USER = '@@profiles/FETCH_CURRENT_USER',
  FETCH_CURRENT_USER_REQUEST = '@@profiles/FETCH_CURRENT_USER_REQUEST',
  FETCH_CURRENT_USER_SUCCESS = '@@profiles/FETCH_CURRENT_USER_SUCCESS',
  FETCH_CURRENT_USER_FAILURE = '@@profiles/FETCH_CURRENT_USER_FAILURE',
}
