import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as authApi from '../auth-api';

import { AuthService } from '../../../lib/services/auth-service';
import {
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  ResetPasswordAction,
} from './actions';
import { sendAnalytics } from '../../analytics/actions';

export default function* resetPassword(action: ResetPasswordAction): SagaIterator {
  yield put(resetPasswordRequest());
  yield put(
    sendAnalytics({
      event: 'resetPasswordRequest',
      category: 'Auth',
      meta: {
        phoneNo: action.data.phoneNumber,
      },
    }),
  );

  try {
    yield call(authApi.resetPassword, action.data);
    yield put(resetPasswordSuccess());
    yield call(AuthService.deleteToken);
    yield call(AuthService.signin);
    yield put(
      sendAnalytics({
        event: 'resetPasswordSuccess',
        category: 'Auth',
        meta: {},
      }),
    );
  } catch (error) {
    yield put(resetPasswordFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'resetPasswordFailure',
        category: 'Auth',
        meta: {
          phoneNo: action.data.phoneNumber,
          error: JSON.parse(error.message),
        },
      }),
    );
  }
}
