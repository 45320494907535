import React from 'react';
import { END } from 'redux-saga';
import App, { AppContext, AppInitialProps } from 'next/app';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import { SagaStore, wrapper } from '../redux';

import '../styles/main.scss';

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('uojdh5/nrr', {
    network: {
      requestSanitizer: (request) => {
        // if the url contains 'ignore'
        if (
          request.url.toLowerCase().indexOf('/auth/access-token') !== -1 ||
          request.url.toLowerCase().indexOf('/account/individual') !== -1 ||
          request.url.toLowerCase().indexOf('/account/corporate') !== -1
        ) {
          // ignore the request response pair
          return null;
        }

        if (request.url.toLowerCase().indexOf('ignore') !== -1) {
          // scrub out the body
          request.body = null;
        }

        request.headers['Authorization'] = null;

        // otherwise log the request normally
        return request;
      },
    },
  });

  Sentry.init({
    dsn: 'https://e9b6a069d022472785f4a692926bf9ab@o368387.ingest.sentry.io/5215271',
  });
}
class MyApp extends App<AppInitialProps> {
  static async getInitialProps({ Component, ctx }: AppContext): Promise<AppInitialProps> {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    // 2. Stop the saga if on server
    if (ctx.req) {
      // Saga is executing on server, we will wait;
      ctx.store.dispatch(END);
      await (ctx.store as SagaStore).sagaTask.toPromise();
    }

    return {
      pageProps,
    };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props;
    return <Component {...pageProps} />;
  }
}

export default wrapper.withRedux(MyApp);
