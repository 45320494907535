/* eslint-disable @typescript-eslint/no-explicit-any */

import { Store, createStore, applyMiddleware } from 'redux';
import { MakeStore, createWrapper, HYDRATE } from 'next-redux-wrapper';
import createSagaMiddleware, { Task } from 'redux-saga';
import logger from 'redux-logger';
import { NextPageContext } from 'next';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { sendAnalytics } from './analytics/actions';
import { AuthService } from '../lib/services/auth-service';
import { saveSessionToken } from './auth/signin/actions';

const isDev = process.env.NODE_ENV !== 'production';
const isServer = typeof window === 'undefined';

export type RootState = ReturnType<typeof rootReducer>;

export interface HydrateAction {
  type: typeof HYDRATE;
  payload: RootState;
}

export interface SagaStore extends Store {
  sagaTask?: Task;
}

export interface ReduxNextPageContext extends NextPageContext {
  store: SagaStore;
}

interface NextContext extends NextPageContext {
  ctx?: NextPageContext;
}

const makeStore: MakeStore<RootState> = (context: NextContext) => {
  if (context.ctx) {
    // console.log(context.ctx.req);
  }

  const sagaMiddleware = createSagaMiddleware();
  const middlewares: any[] = [sagaMiddleware];

  if (isDev && !isServer) {
    middlewares.push(logger);
  }

  const store = createStore(rootReducer, applyMiddleware(...middlewares));

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  const { sessionId, userId } = AuthService.saveSessionToken();

  store.dispatch(saveSessionToken(sessionId, userId));

  if (typeof window !== 'undefined') {
    store.dispatch(
      sendAnalytics({
        event: 'siteLoaded',
        category: 'App',
        meta: {},
      }),
    );

    window.onbeforeunload = () => {
      store.dispatch(
        sendAnalytics({
          event: 'siteExit',
          category: 'App',
          meta: {},
        }),
      );
      //if we return nothing here (just calling return;) then there will be no pop-up question at all
      return;
    };
  }

  return store;
};

export const wrapper = createWrapper<RootState>(makeStore, { debug: false });
