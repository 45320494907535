import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as api from '../copyrights-api';
import {
  updateCopyrightRequest,
  updateCopyrightSuccess,
  updateCopyrightFailure,
  UpdateCopyrightAction,
} from './actions';
import { Copyright } from '../interfaces';
import { sendAnalytics } from '../../analytics/actions';

export default function* updateCopyright(action: UpdateCopyrightAction): SagaIterator {
  yield put(updateCopyrightRequest());
  yield put(
    sendAnalytics({
      event: 'updateCopyrightRequest',
      category: 'Copyright',
      meta: {
        payload: action.data,
        copyrightId: action.copyrightId,
      },
    }),
  );

  try {
    const copyright: Copyright = yield call(api.updateCopyright, action.data, action.copyrightId);

    yield put(updateCopyrightSuccess(copyright));
    yield put(
      sendAnalytics({
        event: 'updateCopyrightSuccess',
        category: 'Copyright',
        meta: {
          copyrightId: action.copyrightId,
        },
      }),
    );
  } catch (error) {
    yield put(updateCopyrightFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'updateCopyrightFailure',
        category: 'Copyright',
        meta: {
          error: JSON.parse(error.message),
          copyrightId: action.copyrightId,
        },
      }),
    );
  }
}
