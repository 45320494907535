import { Categories, SubCategories } from './interfaces';

export enum types {
  FETCH_CATEGORIES = '@@categories/FETCH_CATEGORIES',

  FETCH_CATEGORIES_REQUEST = '@@categories/FETCH_CATEGORIES_REQUEST',
  FETCH_CATEGORIES_SUCCESS = '@@categories/FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE = '@@categories/FETCH_CATEGORIES_FAILURE',
}

export interface FetchCategoriesRequest {
  type: typeof types.FETCH_CATEGORIES_REQUEST;
}

export interface FetchCategoriesSuccess {
  type: typeof types.FETCH_CATEGORIES_SUCCESS;
  categoryIds: string[];
  categories: Categories;
  subCategories: SubCategories;
}

export interface FetchCategoriesFailure {
  type: typeof types.FETCH_CATEGORIES_FAILURE;
}

export interface FetchCategories {
  type: typeof types.FETCH_CATEGORIES;
}

export const fetchCategoriesRequest = () => ({
  type: types.FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = (
  categories: Categories,
  subCategories: SubCategories,
  categoryIds: string[],
) => ({
  type: types.FETCH_CATEGORIES_SUCCESS,
  categories,
  categoryIds,
  subCategories,
});

export const fetchCategoriesFailure = () => ({
  type: types.FETCH_CATEGORIES_FAILURE,
});

export const fetchCategories = () => ({
  type: types.FETCH_CATEGORIES,
});

export type CategoryActions =
  | FetchCategoriesRequest
  | FetchCategoriesSuccess
  | FetchCategoriesFailure
  | FetchCategories;
