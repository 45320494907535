import { types } from '../types';
import { Copyrights } from '../interfaces';

export interface FetchCopyrightsRequest {
  type: typeof types.FETCH_COPYRIGHTS_REQUEST;
}

export interface FetchCopyrightsFailure {
  type: typeof types.FETCH_COPYRIGHTS_FAILURE;
}

export interface FetchApprovedCopyrights {
  type: typeof types.FETCH_APPROVED_COPYRIGHTS;
}

export interface FetchApprovedCopyrightsSuccess {
  type: typeof types.FETCH_APPROVED_COPYRIGHTS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export interface FetchPendingCopyrights {
  type: typeof types.FETCH_PENDING_COPYRIGHTS;
}

export interface FetchPendingCopyrightsSuccess {
  type: typeof types.FETCH_PENDING_COPYRIGHTS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export interface FetchDraftCopyrights {
  type: typeof types.FETCH_DRAFT_COPYRIGHTS;
}

export interface FetchDraftCopyrightsSuccess {
  type: typeof types.FETCH_DRAFT_COPYRIGHTS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export interface FetchRejectedCopyrights {
  type: typeof types.FETCH_REJECTED_COPYRIGHTS;
}

export interface FetchRejectedCopyrightsSuccess {
  type: typeof types.FETCH_REJECTED_COPYRIGHTS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export interface FetchCopyrightDetails {
  type: typeof types.FETCH_COPYRIGHT_DETAILS;
  copyrightId: string;
}

export interface FetchCopyrightDetailsSuccess {
  type: typeof types.FETCH_COPYRIGHT_DETAILS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export const fetchCopyrightsRequest = () => ({
  type: types.FETCH_COPYRIGHTS_REQUEST,
});

export const fetchCopyrightsFailure = () => ({
  type: types.FETCH_COPYRIGHTS_FAILURE,
});

export const fetchApprovedCopyrights = () => ({
  type: types.FETCH_APPROVED_COPYRIGHTS,
});

export const fetchApprovedCopyrightsSuccess = (copyrights: Copyrights, copyrightIds: string[]) => ({
  type: types.FETCH_APPROVED_COPYRIGHTS_SUCCESS,
  copyrights,
  copyrightIds,
});

export const fetchPendingCopyrights = () => ({
  type: types.FETCH_PENDING_COPYRIGHTS,
});

export const fetchPendingCopyrightsSuccess = (copyrights: Copyrights, copyrightIds: string[]) => ({
  type: types.FETCH_PENDING_COPYRIGHTS_SUCCESS,
  copyrights,
  copyrightIds,
});

export const fetchDraftCopyrights = () => ({
  type: types.FETCH_DRAFT_COPYRIGHTS,
});

export const fetchDraftCopyrightsSuccess = (copyrights: Copyrights, copyrightIds: string[]) => ({
  type: types.FETCH_DRAFT_COPYRIGHTS_SUCCESS,
  copyrights,
  copyrightIds,
});

export const fetchRejectedCopyrights = () => ({
  type: types.FETCH_REJECTED_COPYRIGHTS,
});

export const fetchRejectedCopyrightsSuccess = (copyrights: Copyrights, copyrightIds: string[]) => ({
  type: types.FETCH_REJECTED_COPYRIGHTS_SUCCESS,
  copyrights,
  copyrightIds,
});

export const fetchCopyrightDetails = (copyrightId: string) => ({
  type: types.FETCH_COPYRIGHT_DETAILS,
  copyrightId,
});

export const fetchCopyrightDetailsSuccess = (copyrights: Copyrights, copyrightIds: string[]) => ({
  type: types.FETCH_COPYRIGHT_DETAILS_SUCCESS,
  copyrights,
  copyrightIds,
});

export type FetchCopyrightsActions =
  | FetchCopyrightsRequest
  | FetchCopyrightsFailure
  | FetchPendingCopyrightsSuccess
  | FetchApprovedCopyrightsSuccess
  | FetchRejectedCopyrightsSuccess
  | FetchApprovedCopyrights
  | FetchPendingCopyrights
  | FetchDraftCopyrights
  | FetchDraftCopyrightsSuccess
  | FetchRejectedCopyrights
  | FetchCopyrightDetails
  | FetchCopyrightDetailsSuccess;
