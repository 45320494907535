import { normalize } from 'normalizr';
import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery } from 'redux-saga/effects';

import * as api from './categories-api';
import { categoriesModel } from '../schemas';
import { Category, CategoryEntities } from './interfaces';

import {
  types,
  fetchCategoriesRequest,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
} from './actions';

function* fetchCategories(): SagaIterator {
  yield put(fetchCategoriesRequest());

  try {
    const categories = yield call(api.fetchCategories);
    const normalized = normalize<Category[], CategoryEntities, string[]>(
      categories,
      categoriesModel,
    );

    const { result: categoryIds, entities } = normalized;
    yield put(fetchCategoriesSuccess(entities.categories, entities.subCategories, categoryIds));
  } catch (error) {
    yield put(fetchCategoriesFailure());
  }
}

export default function* watchCategories(): SagaIterator {
  yield takeEvery(types.FETCH_CATEGORIES, fetchCategories);
}
