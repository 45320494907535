import { types } from '../types';

export interface NewOTPRequest {
  type: typeof types.NEW_OTP_REQUEST;
}

export interface NewOTPSuccess {
  type: typeof types.NEW_OTP_SUCCESS;
}

export interface NewOTPFailure {
  type: typeof types.NEW_OTP_FAILURE;
  messages: Record<string, string>[];
}

export interface NewOTPAction {
  type: typeof types.NEW_OTP;
  phoneNumber: string;
  redirect?: boolean;
  validate?: boolean;
}

export const newOTPRequest = () => ({
  type: types.NEW_OTP_REQUEST,
});

export const newOTPSuccess = () => ({
  type: types.NEW_OTP_SUCCESS,
});

export const newOTPFailure = (messages: Record<string, string>[]) => ({
  type: types.NEW_OTP_FAILURE,
  messages,
});

export const newOTP = (phoneNumber: string, redirect = true, validate = false): NewOTPAction => {
  return {
    type: types.NEW_OTP,
    phoneNumber,
    redirect,
    validate,
  };
};

export type NewOTPActions = NewOTPRequest | NewOTPSuccess | NewOTPFailure | NewOTPAction;
