import { types } from '../types';

export interface SignoutRequest {
  type: typeof types.SIGNOUT_REQUEST;
}

export interface SignoutAction {
  type: typeof types.SIGNOUT;
}

export const signoutRequest = () => ({
  type: types.SIGNOUT_REQUEST,
});

export const signout = (): SignoutAction => {
  return {
    type: types.SIGNOUT,
  };
};

export type SignoutActions = SignoutRequest | SignoutAction;
