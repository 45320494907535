import { types } from '../types';
import { NewCopyrightDetails, Copyright } from '../interfaces';

interface UpdateCopyrightRequest {
  type: typeof types.UPDATE_COPYRIGHT_REQUEST;
}

interface UpdateCopyrightSuccess {
  type: typeof types.UPDATE_COPYRIGHT_SUCCESS;
  copyright: any;
}

interface UpdateCopyrightFailure {
  type: typeof types.UPDATE_COPYRIGHT_FAILURE;
  messages: Record<string, string>[];
}

export interface UpdateCopyrightAction {
  type: typeof types.UPDATE_COPYRIGHT;
  data: NewCopyrightDetails;
  copyrightId: string;
}

export const updateCopyrightRequest = () => ({
  type: types.UPDATE_COPYRIGHT_REQUEST,
});

export const updateCopyrightSuccess = (copyright: Copyright) => ({
  type: types.UPDATE_COPYRIGHT_SUCCESS,
  copyright,
});

export const updateCopyrightFailure = (messages: Record<string, string>[]) => ({
  type: types.UPDATE_COPYRIGHT_FAILURE,
  messages,
});

export const updateCopyright = (
  data: NewCopyrightDetails,
  copyrightId: string,
): UpdateCopyrightAction => {
  return {
    type: types.UPDATE_COPYRIGHT,
    data,
    copyrightId,
  };
};

export type UpdateCopyrightActions =
  | UpdateCopyrightRequest
  | UpdateCopyrightSuccess
  | UpdateCopyrightFailure
  | UpdateCopyrightAction;
