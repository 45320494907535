import { HYDRATE } from 'next-redux-wrapper';
import { types, ProfileAction } from './types';
import { CurrentUser } from './interfaces';

export interface ProfileState
  extends Readonly<{
    isFetching: boolean;
    currentUser: CurrentUser;
  }> {}

const initialState: ProfileState = {
  isFetching: false,
  currentUser: undefined,
};

const authReducer = (state = initialState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case HYDRATE: {
      const { profiles = {} } = action.payload;

      return {
        ...state,
        ...profiles,
      };
    }
    case types.FETCH_CURRENT_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload,
      };
    }
    case types.FETCH_CURRENT_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        currentUser: undefined,
      };
    default:
      return state;
  }
};

export default authReducer;
