import { HydrateAction } from '../index';
import { RegisterCopyrightActions } from './register-copyright/actions';
import { FetchCopyrightsActions } from './fetch-copyrights/actions';
import { UpdateCopyrightActions } from './update-copyright/actions';
import { SearchCopyrightsActions } from './search-copyrights/actions';
import { DisputeCopyrightActions } from './dispute-copyright/actions';
import { AmendCopyrightActions } from './amend-copyright/actions';
import { HistoricalCopyrightsActions } from './historical-copyrights/actions';

export type CopyrightAction =
  | HydrateAction
  | RegisterCopyrightActions
  | FetchCopyrightsActions
  | UpdateCopyrightActions
  | SearchCopyrightsActions
  | DisputeCopyrightActions
  | AmendCopyrightActions
  | HistoricalCopyrightsActions;

export enum types {
  SAVE_AND_CONTINUE = '@@copyright/SAVE_AND_CONTINUE',

  REGISTER_COPYRIGHT = '@@copyright/REGISTER_COPYRIGHT',
  REGISTER_COPYRIGHT_REQUEST = '@@copyright/REGISTER_COPYRIGHT_REQUEST',
  REGISTER_COPYRIGHT_SUCCESS = '@@copyright/REGISTER_COPYRIGHT_SUCCESS',
  REGISTER_COPYRIGHT_FAILURE = '@@copyright/REGISTER_COPYRIGHT_FAILURE',

  FETCH_COPYRIGHTS_FAILURE = '@@copyright/FETCH_COPYRIGHTS_FAILURE',
  FETCH_COPYRIGHTS_REQUEST = '@@copyright/FETCH_COPYRIGHTS_REQUEST',

  FETCH_APPROVED_COPYRIGHTS = '@@copyright/FETCH_APPROVED_COPYRIGHTS',
  FETCH_APPROVED_COPYRIGHTS_SUCCESS = '@@copyright/FETCH_APPROVED_COPYRIGHTS_SUCCESS',

  FETCH_PENDING_COPYRIGHTS = '@@copyright/FETCH_PENDING_COPYRIGHTS',
  FETCH_PENDING_COPYRIGHTS_SUCCESS = '@@copyright/FETCH_PENDING_COPYRIGHTS_SUCCESS',

  FETCH_REJECTED_COPYRIGHTS = '@@copyright/FETCH_REJECTED_COPYRIGHTS',
  FETCH_REJECTED_COPYRIGHTS_SUCCESS = '@@copyright/FETCH_REJECTED_COPYRIGHTS_SUCCESS',

  FETCH_COPYRIGHT_DETAILS = '@@copyright/FETCH_COPYRIGHT_DETAILS',
  FETCH_COPYRIGHT_DETAILS_SUCCESS = '@@copyright/FETCH_COPYRIGHT_DETAILS_SUCCESS',

  FETCH_DRAFT_COPYRIGHTS = '@@copyright/FETCH_DRAFT_COPYRIGHTS',
  FETCH_DRAFT_COPYRIGHTS_SUCCESS = '@@copyright/FETCH_DRAFT_COPYRIGHTS_SUCCESS',

  UPDATE_COPYRIGHT = '@@copyright/UPDATE_COPYRIGHT',
  UPDATE_COPYRIGHT_REQUEST = '@@copyright/UPDATE_COPYRIGHT_REQUEST',
  UPDATE_COPYRIGHT_SUCCESS = '@@copyright/UPDATE_COPYRIGHT_SUCCESS',
  UPDATE_COPYRIGHT_FAILURE = '@@copyright/UPDATE_COPYRIGHT_FAILURE',

  SEARCH_COPYRIGHTS = '@@copyright/SEARCH_COPYRIGHTS',
  SEARCH_COPYRIGHTS_FAILURE = '@@copyright/SEARCH_COPYRIGHTS_FAILURE',
  SEARCH_COPYRIGHTS_REQUEST = '@@copyright/SEARCH_COPYRIGHTS_REQUEST',
  SEARCH_COPYRIGHTS_SUCCESS = '@@copyright/SEARCH_COPYRIGHTS_SUCCESS',

  DISPUTE_COPYRIGHT = '@@copyright/DISPUTE_COPYRIGHT',
  DISPUTE_COPYRIGHT_FAILURE = '@@copyright/DISPUTE_COPYRIGHT_FAILURE',
  DISPUTE_COPYRIGHT_REQUEST = '@@copyright/DISPUTE_COPYRIGHT_REQUEST',
  DISPUTE_COPYRIGHT_SUCCESS = '@@copyright/DISPUTE_COPYRIGHT_SUCCESS',

  FETCH_HISTORICAL_COPYRIGHTS = '@@copyright/FETCH_HISTORICAL_COPYRIGHTS',
  FETCH_HISTORICAL_COPYRIGHTS_FAILURE = '@@copyright/FETCH_HISTORICAL_COPYRIGHTS_FAILURE',
  FETCH_HISTORICAL_COPYRIGHTS_REQUEST = '@@copyright/FETCH_HISTORICAL_COPYRIGHTS_REQUEST',
  FETCH_HISTORICAL_COPYRIGHTS_SUCCESS = '@@copyright/FETCH_HISTORICAL_COPYRIGHTS_SUCCESS',

  HISTORICAL_COPYRIGHT_UPDATED = '@@copyright/HISTORICAL_COPYRIGHT_UPDATED',

  FETCH_HISTORICAL_COPYRIGHT_DETAILS = '@@copyright/FETCH_HISTORICAL_COPYRIGHT_DETAILS',
  FETCH_HISTORICAL_COPYRIGHT_DETAILS_SUCCESS = '@@copyright/FETCH_HISTORICAL_COPYRIGHT_DETAILS_SUCCESS',

  AMEND_COPYRIGHT = '@@copyright/AMEND_COPYRIGHT',
  AMEND_COPYRIGHT_FAILURE = '@@copyright/AMEND_COPYRIGHT_FAILURE',
  AMEND_COPYRIGHT_REQUEST = '@@copyright/AMEND_COPYRIGHT_REQUEST',
  AMEND_COPYRIGHT_SUCCESS = '@@copyright/AMEND_COPYRIGHT_SUCCESS',
}
