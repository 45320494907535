import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as authApi from '../auth-api';
import { AuthToken } from '../interfaces';
import { AuthService } from '../../../lib/services/auth-service';
import {
  signinRequest,
  signinSuccess,
  signinFailure,
  SigninAction,
  saveSessionToken,
} from './actions';
import { sendAnalytics } from '../../analytics/actions';

export default function* signin(action: SigninAction): SagaIterator {
  yield put(signinRequest());
  yield put(
    sendAnalytics({
      event: 'signInRequest',
      category: 'Auth',
      meta: {
        phoneNo: action.data.username,
      },
    }),
  );

  try {
    const authToken: AuthToken = yield call(authApi.signin, action.data);
    yield put(signinSuccess(authToken.access_token));
    yield call(AuthService.saveToken, authToken.access_token);

    const _userId = AuthService.decodeToken(authToken.access_token);
    const { sessionId, userId } = AuthService.saveSessionToken(_userId);

    yield put(saveSessionToken(sessionId, userId));

    yield put(
      sendAnalytics({
        event: 'signInSuccess',
        category: 'Auth',
        meta: {},
      }),
    );
  } catch (error) {
    yield put(signinFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'signInFailure',
        category: 'Auth',
        meta: {
          phoneNo: action.data.username,
          error: JSON.parse(error.message),
        },
      }),
    );
  }
}
