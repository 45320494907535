import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import { types } from './types';
import registerCopyrightSaga from './register-copyright/sagas';
import {
  fetchApprovedCopyrights,
  fetchPendingCopyrights,
  fetchRejectedCopyrights,
  fetchCopyrightDetails,
  fetchDraftCopyrights,
} from './fetch-copyrights/sagas';
import updateCopyright from './update-copyright/sagas';
import searchCopyrights from './search-copyrights/sagas';
import disputeCopyright from './dispute-copyright/sagas';
import amendCopyright from './amend-copyright/sagas';
import {
  fetchHistoricalCopyrights,
  fetchHistoricalCopyrightDetails,
} from './historical-copyrights/sagas';

export default function* watchCopyrights(): SagaIterator {
  yield takeLatest(types.REGISTER_COPYRIGHT, registerCopyrightSaga);
  yield takeLatest(types.FETCH_PENDING_COPYRIGHTS, fetchPendingCopyrights);
  yield takeLatest(types.FETCH_DRAFT_COPYRIGHTS, fetchDraftCopyrights);
  yield takeLatest(types.FETCH_APPROVED_COPYRIGHTS, fetchApprovedCopyrights);
  yield takeLatest(types.FETCH_REJECTED_COPYRIGHTS, fetchRejectedCopyrights);
  yield takeLatest(types.FETCH_COPYRIGHT_DETAILS, fetchCopyrightDetails);
  yield takeLatest(types.UPDATE_COPYRIGHT, updateCopyright);
  yield takeLatest(types.SEARCH_COPYRIGHTS, searchCopyrights);
  yield takeLatest(types.DISPUTE_COPYRIGHT, disputeCopyright);
  yield takeLatest(types.AMEND_COPYRIGHT, amendCopyright);
  yield takeLatest(types.FETCH_HISTORICAL_COPYRIGHTS, fetchHistoricalCopyrights);
  yield takeLatest(types.FETCH_HISTORICAL_COPYRIGHT_DETAILS, fetchHistoricalCopyrightDetails);
}
