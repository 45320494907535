import { HYDRATE } from 'next-redux-wrapper';

import { Users } from './interfaces';
import { types, UsersAction } from './actions';
import { mergeIds, mergeObjects } from '../utils';

import { HydrateAction } from '../index';

export interface UsersState
  extends Readonly<{
    users: Users;
    userIds: string[];
    isFetching: boolean;
  }> {}

const initialState: UsersState = {
  users: {},
  userIds: [],
  isFetching: false,
};

const usersReducer = (state = initialState, action: UsersAction | HydrateAction): UsersState => {
  switch (action.type) {
    case HYDRATE: {
      const { users = {} } = action.payload;

      return {
        ...state,
        ...users,
      };
    }
    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_USERS_SUCCESS: {
      const { userIds, users } = action;

      return {
        ...state,
        isFetching: false,
        userIds: mergeIds<string>(state.userIds, userIds),
        users: mergeObjects<Users, string>(userIds, state.users, users),
      };
    }
    case types.FETCH_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
