import { apiService } from '../../lib/services/api-service';
import { CreateInvoice, InvoiceErrors, Invoice, Service } from './interfaces';

export const generateInvoice = async (data: CreateInvoice) => {
  return apiService.post<CreateInvoice, any, InvoiceErrors>('/transactions/invoices', data);
};

export const fetchPendingInvoices = async () => {
  return await apiService.get<Invoice[]>('/transactions/pending-invoices');
};

export const fetchServices = async () => {
  return await apiService.get<Service[]>('/transactions/services');
};
