/* eslint-disable @typescript-eslint/no-unused-vars */

import { formartPhoneNo } from '../../lib/utils';
import { apiService } from '../../lib/services/api-service';
import {
  AuthToken,
  SigninInputs,
  SigninErrors,
  NewOTPInputs,
  NewOTPResponse,
  NewOTPErrors,
  ValidateOTPInputs,
  ValidateOTPResponse,
  ValidateOTPErrors,
  PasswordInput,
  ResetPasswordInputs,
  ResetPasswordResponse,
  ResetPasswordErrors,
  SignupIndividual,
  SignupIndividualInputs,
  SignupResponse,
  SignupIndividualErrors,
  SignupCorporate,
  SignupCorporateInputs,
  SignupCorporateErrors,
} from './interfaces';

export const signin = async (data: SigninInputs) => {
  const formData = new FormData();

  formData.append('username', formartPhoneNo(data.username));
  formData.append('password', data.password);

  return apiService.post<FormData, AuthToken, SigninErrors>('/auth/access-token', formData);
};

export const newOTP = async (phoneNumber: string, validate = false) => {
  return apiService.post<NewOTPInputs, NewOTPResponse, NewOTPErrors>(
    `/account/send-otp?validate=${validate}`,
    {
      phoneNumber,
    },
  );
};

export const validateOTP = async (data: ValidateOTPInputs) => {
  return apiService.post<ValidateOTPInputs, ValidateOTPResponse, ValidateOTPErrors>(
    '/account/verify-otp',
    data,
  );
};

export const resetPassword = async (data: ResetPasswordInputs) => {
  return apiService.put<PasswordInput, ResetPasswordResponse, ResetPasswordErrors>(
    '/account/reset-password',
    {
      password: data.password,
    },
  );
};

export const signupIndividual = async (data: SignupIndividualInputs) => {
  const { confirmPassword, acceptTerms, ...signupData } = data;
  return apiService.post<SignupIndividual, SignupResponse, SignupIndividualErrors>(
    '/account/individual',
    signupData,
  );
};

export const signupCorporate = async (data: SignupCorporateInputs) => {
  const { confirmPassword, acceptTerms, ...signupData } = data;
  return apiService.post<SignupCorporate, SignupResponse, SignupCorporateErrors>(
    '/account/corporate',
    signupData,
  );
};
