import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as api from '../copyrights-api';
import {
  registerCopyrightRequest,
  registerCopyrightSuccess,
  registerCopyrightFailure,
  RegisterCopyrightAction,
} from './actions';
import { historicalCopyrightUpdated } from '../historical-copyrights/actions';
import { Copyright } from '../interfaces';
import { sendAnalytics } from '../../analytics/actions';

export default function* registerCopyright(action: RegisterCopyrightAction): SagaIterator {
  yield put(registerCopyrightRequest());
  yield put(
    sendAnalytics({
      event: 'registerCopyrightRequest',
      category: 'Copyright',
      meta: {
        payload: action.data,
      },
    }),
  );

  try {
    const copyright: Copyright = yield call(api.registerCopyright, action.data, action.isDraft);

    yield put(registerCopyrightSuccess(copyright));
    yield put(
      sendAnalytics({
        event: 'registerCopyrightSuccess',
        category: 'Copyright',
        meta: {
          copyrightId: copyright.id,
        },
      }),
    );

    if (action.data?.workDetails?.id !== undefined)
      yield put(historicalCopyrightUpdated(action.data.workDetails.id));
  } catch (error) {
    yield put(registerCopyrightFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'registerCopyrightFailure',
        category: 'Copyright',
        meta: {
          error: JSON.parse(error.message),
          payload: action.data,
        },
      }),
    );
  }
}
