import { HYDRATE } from 'next-redux-wrapper';
import { types, TransactionsActions } from './actions';
import { mergeIds, mergeObjects } from '../utils';
import { Invoice, Invoices, Services } from './interfaces';

export interface TransactionsState
  extends Readonly<{
    isFetching: boolean;
    copyrightIds: string[];
    invoiceIds: string[];
    invoices: Invoices;
    serviceIds: string[];
    services: Services;
    currentInvoice?: Invoice;
  }> {}

const initialState: TransactionsState = {
  isFetching: false,
  copyrightIds: [],
  invoiceIds: [],
  invoices: {},
  serviceIds: [],
  services: {},
  currentInvoice: undefined,
};

const transactionsReducer = (
  state = initialState,
  action: TransactionsActions,
): TransactionsState => {
  switch (action.type) {
    case HYDRATE: {
      const { transactions = {} } = action.payload;

      return {
        ...state,
        ...transactions,
      };
    }

    case types.GENERATE_INVOICE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GENERATE_INVOICE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        copyrightIds: [],
        currentInvoice: action.invoice,
      };
    }
    case types.GENERATE_INVOICE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.ADD_TO_INVOICE:
      return {
        ...state,
        copyrightIds: mergeIds<string>(state.copyrightIds, [action.copyrightId]),
      };
    case types.FETCH_PENDING_INVOICES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_PENDING_INVOICES_SUCCESS: {
      const { invoiceIds, invoices } = action;

      return {
        ...state,
        isFetching: false,
        invoiceIds: mergeIds<string>(state.invoiceIds, invoiceIds),
        invoices: mergeObjects<Invoices, string>(invoiceIds, state.invoices, invoices),
      };
    }
    case types.FETCH_PENDING_INVOICES_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case types.FETCH_SERVICES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_SERVICES_SUCCESS: {
      const { serviceIds, services } = action;

      return {
        ...state,
        isFetching: false,
        serviceIds: mergeIds<string>(state.serviceIds, serviceIds),
        services: mergeObjects<Services, string>(serviceIds, state.services, services),
      };
    }
    case types.FETCH_SERVICES_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

export default transactionsReducer;
