import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import { types } from './types';
import signinSaga from './signin/sagas';
import signoutSaga from './signout/sagas';
import { signupIndividual, signupCorporate } from './signup/sagas';
import validateOTPSaga from './otp-validate/sagas';
import newOTPSaga from './otp-request/sagas';
import resetPasswordSaga from './reset-password/sagas';

export default function* watchAuth(): SagaIterator {
  yield takeLatest(types.SIGNIN, signinSaga);
  yield takeLatest(types.SIGNOUT, signoutSaga);
  yield takeLatest(types.NEW_OTP, newOTPSaga);
  yield takeLatest(types.VALIDATE_OTP, validateOTPSaga);
  yield takeLatest(types.RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(types.SIGNUP_INDIVIDUAL, signupIndividual);
  yield takeLatest(types.SIGNUP_CORPORATE, signupCorporate);
}
