import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as authApi from '../auth-api';
import { AuthToken } from '../interfaces';
import { AuthService } from '../../../lib/services/auth-service';
import {
  validateOTPRequest,
  validateOTPSuccess,
  validateOTPFailure,
  ValidateOTPAction,
} from './actions';
import { sendAnalytics } from '../../analytics/actions';
import { saveSessionToken } from '../signin/actions';

export default function* validateOTP(action: ValidateOTPAction): SagaIterator {
  yield put(validateOTPRequest());
  const { resetPassword = false, phoneNumber } = action.meta || {};

  yield put(
    sendAnalytics({
      event: 'validateOTPRequest',
      category: 'Auth',
      meta: {
        phoneNo: phoneNumber,
      },
    }),
  );

  try {
    const authToken: AuthToken = yield call(authApi.validateOTP, action.payload);
    yield put(validateOTPSuccess(authToken.access_token));
    yield call(AuthService.saveToken, authToken.access_token, false);

    const _userId = AuthService.decodeToken(authToken.access_token);
    const { sessionId, userId } = AuthService.saveSessionToken(_userId);

    yield put(saveSessionToken(sessionId, userId));

    if (resetPassword) {
      yield call(AuthService.resetPassword, userId, phoneNumber);
    }
    yield put(
      sendAnalytics({
        event: 'validateOTPSuccess',
        category: 'Auth',
        meta: {},
      }),
    );
  } catch (error) {
    yield put(validateOTPFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'validateOTPFailure',
        category: 'Auth',
        meta: {
          phoneNo: phoneNumber,
          error: JSON.parse(error.message),
        },
      }),
    );
  }
}
