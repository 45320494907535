import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import { AuthService } from '../../../lib/services/auth-service';
import { signoutRequest } from './actions';
import { sendAnalytics } from '../../analytics/actions';

export default function* signout(): SagaIterator {
  yield put(signoutRequest());
  yield put(
    sendAnalytics({
      event: 'signout',
      category: 'Auth',
      meta: {},
    }),
  );
  yield call(AuthService.deleteToken);
}
