import { types, AnalyticsActions } from './actions';

export interface AnalyticsState
  extends Readonly<{
    isSending: boolean;
  }> {}

const initialState: AnalyticsState = {
  isSending: false,
};

const analyticsReducer = (state = initialState, action: AnalyticsActions): AnalyticsState => {
  switch (action.type) {
    case types.SEND_ANALYTICS_REQUEST:
      return {
        ...state,
        isSending: true,
      };
    case types.SEND_ANALYTICS_SUCCESS: {
      return {
        ...state,
        isSending: false,
      };
    }
    case types.SEND_ANALYTICS_FAILURE:
      return {
        ...state,
        isSending: false,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
