import { types } from './types';
import { CurrentUser } from './interfaces';

export interface FetchCurrentUserRequest {
  type: typeof types.FETCH_CURRENT_USER_REQUEST;
}

export interface FetchCurrentUserSuccess {
  type: typeof types.FETCH_CURRENT_USER_SUCCESS;
  payload: CurrentUser;
}

export interface FetchCurrentUserFailure {
  type: typeof types.FETCH_CURRENT_USER_FAILURE;
}

export interface FetchCurrentUser {
  type: typeof types.FETCH_CURRENT_USER;
}

export type FetchCurrentUserAction =
  | FetchCurrentUserRequest
  | FetchCurrentUserSuccess
  | FetchCurrentUserFailure
  | FetchCurrentUser;

export const fetchCurrentUserRequest = () => ({
  type: types.FETCH_CURRENT_USER_REQUEST,
});

export const fetchCurrentUserSuccess = (payload: CurrentUser) => ({
  type: types.FETCH_CURRENT_USER_SUCCESS,
  payload,
});

export const fetchCurrentUserFailure = () => ({
  type: types.FETCH_CURRENT_USER_FAILURE,
});

export const fetchCurrentUser = () => ({
  type: types.FETCH_CURRENT_USER,
});
