import { types } from '../types';
import { ResetPasswordInputs } from '../interfaces';

interface ResetPasswordRequest {
  type: typeof types.RESET_PASSWORD_REQUEST;
}

interface ResetPasswordSuccess {
  type: typeof types.RESET_PASSWORD_SUCCESS;
}

interface ResetPasswordFailure {
  type: typeof types.RESET_PASSWORD_FAILURE;
  messages: Record<string, string>[];
}

export interface ResetPasswordAction {
  type: typeof types.RESET_PASSWORD;
  data: ResetPasswordInputs;
}

export const resetPasswordRequest = () => ({
  type: types.RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (messages: Record<string, string>[]) => ({
  type: types.RESET_PASSWORD_FAILURE,
  messages,
});

export const resetPassword = (data: ResetPasswordInputs): ResetPasswordAction => {
  return {
    type: types.RESET_PASSWORD,
    data,
  };
};

export type ResetPasswordActions =
  | ResetPasswordRequest
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | ResetPasswordAction;
