import { types } from '../types';
import { Copyrights } from '../interfaces';

export interface SearchCopyrightsRequest {
  type: typeof types.SEARCH_COPYRIGHTS_REQUEST;
}

export interface SearchCopyrightsFailure {
  type: typeof types.SEARCH_COPYRIGHTS_FAILURE;
}

export interface SearchCopyrightsSuccess {
  type: typeof types.SEARCH_COPYRIGHTS_SUCCESS;
  copyrightIds: string[];
  copyrights: Copyrights;
}

export interface SearchCopyrightAction {
  type: typeof types.SEARCH_COPYRIGHTS;
  term: string;
}

export const searchCopyrightsRequest = () => ({
  type: types.SEARCH_COPYRIGHTS_REQUEST,
});

export const searchCopyrightsFailure = () => ({
  type: types.SEARCH_COPYRIGHTS_FAILURE,
});

export const searchCopyrightsSuccess = (copyrights: Copyrights, copyrightIds: string[]) => ({
  type: types.SEARCH_COPYRIGHTS_SUCCESS,
  copyrights,
  copyrightIds,
});

export const searchCopyrights = (term: string): SearchCopyrightAction => {
  return {
    type: types.SEARCH_COPYRIGHTS,
    term,
  };
};

export type SearchCopyrightsActions =
  | SearchCopyrightsRequest
  | SearchCopyrightsFailure
  | SearchCopyrightsSuccess;
