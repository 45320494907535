import { types } from '../types';
import { SigninInputs } from '../interfaces';

export interface SigninRequest {
  type: typeof types.SIGNIN_REQUEST;
}

export interface SigninSuccess {
  type: typeof types.SIGNIN_SUCCESS;
  authToken: string;
}

export interface SigninFailure {
  type: typeof types.SIGNIN_FAILURE;
  messages: Record<string, string>[];
}

export interface SigninAction {
  type: typeof types.SIGNIN;
  data: SigninInputs;
}

export interface SaveSessiontokenAction {
  type: typeof types.SAVE_SESSION_TOKEN;
  userId?: string;
  sessionId: string;
}

export const signinRequest = () => ({
  type: types.SIGNIN_REQUEST,
});

export const signinSuccess = (authToken: string) => ({
  type: types.SIGNIN_SUCCESS,
  authToken,
});

export const signinFailure = (messages: Record<string, string>[]) => ({
  type: types.SIGNIN_FAILURE,
  messages,
});

export const signin = (data: SigninInputs): SigninAction => {
  return {
    type: types.SIGNIN,
    data,
  };
};

export const saveSessionToken = (sessionId: string, userId?: string): SaveSessiontokenAction => {
  return {
    type: types.SAVE_SESSION_TOKEN,
    userId,
    sessionId,
  };
};

export type SigninActions =
  | SigninRequest
  | SigninSuccess
  | SigninFailure
  | SigninAction
  | SaveSessiontokenAction;
