import React from 'react';
import { Subtract } from 'utility-types';

import { useDispatch } from 'react-redux';
import { sendAnalytics } from '../redux/analytics/actions';

export interface WithAnalyticsProps {
  sendAnalytics: (event: string, category: string, meta?: { [index: string]: any }) => void;
}

// used to inject sendAnalytics prop to wrapped components
export const withAnalytics = <P extends WithAnalyticsProps>(Component: React.ComponentType<P>) => {
  // subtract the injected props from the passed in component’s props
  // this prevents typescript from requiring sendAnalytics prop to be passed to the wrapped component
  // it also errors if sendAnalytics prop is passed to the wrapped component
  const WithAnalytics: React.FC<Subtract<P, WithAnalyticsProps>> = (props) => {
    const dispatch = useDispatch();

    const _sendAnalytics = (
      event: string,
      category: string,
      meta: {
        [index: string]: any;
      } = {},
    ) => {
      dispatch(
        sendAnalytics({
          event,
          category,
          meta,
        }),
      );
    };

    return (
      <>
        <Component {...(props as P)} sendAnalytics={_sendAnalytics} />
      </>
    );
  };

  return WithAnalytics;
};
