import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as api from '../copyrights-api';

import {
  disputeCopyrightRequest,
  disputeCopyrightSuccess,
  disputeCopyrightFailure,
  DisputeCopyrightAction,
} from './actions';

export default function* disputeCopyright(action: DisputeCopyrightAction): SagaIterator {
  yield put(disputeCopyrightRequest());

  try {
    yield call(api.disputeCopyright, action.notes, action.copyrightId);

    yield put(disputeCopyrightSuccess());
  } catch (error) {
    yield put(disputeCopyrightFailure());
  }
}
