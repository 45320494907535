import React, { useEffect } from 'react';
import Link from 'next/link';
import { NextPage } from 'next';

import logoImg from '../assets/images/kecobo-logo.png';
import backgroundImg from '../assets/images/onboarding-desktop.jpg';
import { withAnalytics, WithAnalyticsProps } from '../HOC/with-analytics';

type NavButtonProps = {
  href: string;
  title: string;
  className?: string;
};

const NavButton: React.FC<NavButtonProps> = (props) => (
  <div className={`rounded-full px-5 sm:px-6 ${props.className}`}>
    <Link href={props.href}>
      <a className="block py-3 text-lg font-medium text-white bg-arrow-right sm:py-4">
        {props.title}
      </a>
    </Link>
  </div>
);

const IndexPage: NextPage<WithAnalyticsProps> = (props) => {
  useEffect(() => {
    props.sendAnalytics('landingPage', 'Navigation');
  }, []);

  return (
    <div className="bg-white">
      <div className="flex h-full">
        <div className="flex items-center flex-1 px-6 lg:px-12">
          <div className="max-w-lg mx-auto my-12 text-gray-900 md:max-w-xl">
            <div className="w-40 mx-auto lg:w-48">
              <img src={logoImg} alt="" />
            </div>

            <p className="mt-10 text-3xl font-bold leading-none tracking-wide text-center text-header sm:text-5xl lg:mt-12">
              Kenya National Rights Registry Portal
            </p>

            <p className="px-0 mt-6 text-sm font-normal text-justify lg:font-medium sm:px-0 sm:text-lg lg:mt-12">
              The National Rights Registry (NRR) portal is the central repository collating details
              pertaining to ownership of various copyright works. This portal is used by copyright
              holders allowing them to register and view/download copyright certificates. The portal
              allows rights holders of the following types of works: music, video, spoken word,
              literary works etc.
            </p>

            <div className="py-2 mt-12">
              {/* <NavButton
                href="/signup-individual"
                title="Create Individual Account"
                className="bg-primary"
              /> */}

              <NavButton href="/signup" title="Create Account" className="bg-primary" />

              <NavButton
                href="/signin"
                title="Sign In"
                className="mt-4 bg-red-600 bg-black-primary-1"
              />

              <NavButton
                href="/search"
                title="Search Copyright Database"
                className="mt-4 bg-tertiary"
              />
            </div>

            <div className="pl-6 mt-6">
              <p className="mt-4 font-medium text-center text-md">
                Check out our{' '}
                <Link href="/faq">
                  <a className="text-secondary"> FAQ Section</a>
                </Link>{' '}
                for more information.
              </p>
            </div>
          </div>
        </div>

        <div
          className="flex-1 hidden bg-center bg-cover lg:block"
          style={{ backgroundImage: `url(${backgroundImg})` }}
        />
      </div>
    </div>
  );
};

export default withAnalytics(IndexPage);
