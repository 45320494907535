import { all } from 'redux-saga/effects';

import watchUsers from './users/sagas';
import watchAuth from './auth/sagas';
import watchProfiles from './profiles/sagas';
import watchCategories from './categories/sagas';
import watchCopyrights from './copyrights/sagas';
import watchAnalyticsSaga from './analytics/sagas';
import watchTransactionsSaga from './transactions/sagas';

export default function* rootSaga() {
  yield all([
    watchUsers(),
    watchAuth(),
    watchProfiles(),
    watchCategories(),
    watchCopyrights(),
    watchAnalyticsSaga(),
    watchTransactionsSaga(),
  ]);
}
