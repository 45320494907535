import { apiService } from '../../lib/services/api-service';
import {
  CreateCopyrightInputs,
  NewCopyrightDetails,
  Copyright,
  CreateCopyrightErrors,
} from './interfaces';

export const registerCopyright = async (data: NewCopyrightDetails, isDraft = false) => {
  const {
    workDetails,
    applicants,
    authors,
    performers,
    producers,
    publishers = [],
    documents = [],
  } = data;

  const copyrightData = {
    categoryId: workDetails.categoryId,
    subCategoryId: workDetails.subCategoryId,
    title: workDetails.title,
    genre: workDetails.genre,
    language: workDetails.language,
    album: workDetails.album,
    label: workDetails.label,
    dateReleased: workDetails.dateReleased,
    artifacts: workDetails.artifacts,
    // artifacts: [
    //   {
    //     fileName: workDetails.fileName,
    //     fileType: workDetails.fileType,
    //     artifactUrl: workDetails.artifactUrl,
    //   },
    // ],
    isPublished: workDetails.isPublished === 'yes',
    abstract: workDetails.abstract,
    isCommissioned: workDetails.isCommissioned === 'yes',
    isbn: workDetails.isbn,
    applicants,
    authors,
    producers,
    performers,
    publishers,
    documents,
  };

  if (isDraft) copyrightData['draftId'] = workDetails?.id;

  return apiService.post<CreateCopyrightInputs, Copyright, CreateCopyrightErrors>(
    '/copyrights',
    copyrightData,
  );
};

export const fetchApprovedCopyrights = async () => {
  return await apiService.get<Copyright[]>('/copyrights');
};

export const fetchPendingCopyrights = async () => {
  return await apiService.get<Copyright[]>('/copyrights/pending');
};

export const fetchDraftCopyrights = async () => {
  return await apiService.get<Copyright[]>('/copyrights/drafts');
};

export const fetchRejectedCopyrights = async () => {
  return await apiService.get<Copyright[]>('/copyrights/rejected');
};

export const fetchCopyrightDetails = async (copyrightId: string) => {
  return await apiService.get<Copyright[]>(`/copyrights/${copyrightId}`);
};

export const updateCopyright = async (data: NewCopyrightDetails, copyrightId: string) => {
  const {
    workDetails,
    applicants,
    authors,
    performers,
    producers,
    publishers = [],
    documents = [],
  } = data;

  const copyrightData = {
    categoryId: workDetails.categoryId,
    subCategoryId: workDetails.subCategoryId,
    title: workDetails.title,
    genre: workDetails.genre,
    language: workDetails.language,
    album: workDetails.album,
    label: workDetails.label,
    dateReleased: workDetails.dateReleased,
    artifacts: workDetails.artifacts,
    isPublished: workDetails.isPublished === 'yes',
    abstract: workDetails.abstract,
    isCommissioned: workDetails.isCommissioned === 'yes',
    isbn: workDetails.isbn,
    applicants,
    authors,
    producers,
    performers,
    publishers,
    documents,
  };

  return apiService.put<CreateCopyrightInputs, Copyright, CreateCopyrightErrors>(
    `/copyrights/${copyrightId}`,
    copyrightData,
  );
};

export const searchCopyrights = async (term: string) => {
  return await apiService.get<Copyright[]>(`/copyrights/search?q=${term}`);
};

export const disputeCopyright = async (notes: string, copyrightId: string) => {
  const disputeData = {
    notes,
  };

  return apiService.post<any, any, any>(`/copyrights/${copyrightId}/dispute`, disputeData);
};

export const fetchHistoricalCopyrights = async () => {
  return await apiService.get<Copyright[]>(`/old-copyrights?page=0&size=10`);
};

export const fetchHistoricalCopyrightDetails = async (copyrightId: string) => {
  return await apiService.get<Copyright[]>(`/old-copyrights/${copyrightId}`);
};

export const amendCopyright = async (requestType: string, copyrightId: string) => {
  const data = {
    requestType,
  };

  return apiService.post<any, any, any>(`/copyrights/${copyrightId}/ammend`, data);
};
