import { HydrateAction } from '../index';
import { SigninActions } from './signin/actions';
import { SignoutActions } from './signout/actions';
import { NewOTPActions } from './otp-request/actions';
import { ValidateOTPActions } from './otp-validate/actions';
import { ResetPasswordActions } from './reset-password/actions';
import { SignupActions } from './signup/actions';

export type AuthAction =
  | HydrateAction
  | SigninActions
  | NewOTPActions
  | ValidateOTPActions
  | ResetPasswordActions
  | SignupActions
  | SignoutActions;

export enum types {
  SIGNIN = '@@auth/SIGNIN',
  SIGNIN_REQUEST = '@@auth/SIGNIN_REQUEST',
  SIGNIN_SUCCESS = '@@auth/SIGNIN_SUCCESS',
  SIGNIN_FAILURE = '@@auth/SIGNIN_FAILURE',

  SIGNUP_REQUEST = '@@auth/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = '@@auth/SIGNUP_SUCCESS',
  SIGNUP_FAILURE = '@@auth/SIGNUP_FAILURE',
  SIGNUP_CORPORATE = '@@auth/SIGNUP_CORPORATE',
  SIGNUP_INDIVIDUAL = '@@auth/SIGNUP_INDIVIDUAL',

  SIGNOUT = '@@auth/SIGNOUT',
  SIGNOUT_REQUEST = '@@auth/SIGNOUT_REQUEST',

  VALIDATE_OTP = '@@auth/VALIDATE_OTP',
  VALIDATE_OTP_REQUEST = '@@auth/VALIDATE_OTP_REQUEST',
  VALIDATE_OTP_SUCCESS = '@@auth/VALIDATE_OTP_SUCCESS',
  VALIDATE_OTP_FAILURE = '@@auth/VALIDATE_OTP_FAILURE',

  NEW_OTP = '@@auth/NEW_OTP',
  NEW_OTP_REQUEST = '@@auth/NEW_OTP_REQUEST',
  NEW_OTP_SUCCESS = '@@auth/NEW_OTP_SUCCESS',
  NEW_OTP_FAILURE = '@@auth/NEW_OTP_FAILURE',

  RESET_PASSWORD = '@@auth/RESET_PASSWORD',
  RESET_PASSWORD_REQUEST = '@@auth/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = '@@auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = '@@auth/RESET_PASSWORD_FAILURE',

  SAVE_SESSION_TOKEN = '@@auth/SAVE_SESSION_TOKEN',
}
