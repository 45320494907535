import { schema } from 'normalizr';

export const user = new schema.Entity('users');
export const usersModel = [user];

const subCategory = new schema.Entity('subCategories');
export const subCategoriesModel = [subCategory];

export const category = new schema.Entity('categories', {
  subCategories: [subCategory],
});

export const categoriesModel = [category];

export const copyright = new schema.Entity('copyrights');
export const copyrightsModel = [copyright];

export const invoice = new schema.Entity('invoices');
export const invoicesModel = [invoice];

export const service = new schema.Entity('services');
export const servicesModel = [service];
