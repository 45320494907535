import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as authApi from '../auth-api';
import { formartPhoneNo } from '../../../lib/utils';
import { AuthService } from '../../../lib/services/auth-service';
import {
  signupRequest,
  signupSuccess,
  signupFailure,
  SignupIndividualAction,
  SignupCorporateAction,
} from './actions';
import { sendAnalytics } from '../../analytics/actions';

export function* signupIndividual(action: SignupIndividualAction): SagaIterator {
  yield put(signupRequest());
  yield put(
    sendAnalytics({
      event: 'individualSignupRequest',
      category: 'Auth',
      meta: {
        phoneNo: action.data.phoneNumber,
      },
    }),
  );

  try {
    const phoneNumber = formartPhoneNo(action.data.phoneNumber);
    const data = {
      ...action.data,
      phoneNumber,
    };
    const { userId } = yield call(authApi.signupIndividual, data);
    yield put(signupSuccess({ userId, phoneNumber }));
    yield call(AuthService.verifyPhoneNo, userId, phoneNumber, '/signin');
    yield put(
      sendAnalytics({
        event: 'individualSignupSuccess',
        category: 'Auth',
        meta: {},
      }),
    );
  } catch (error) {
    yield put(signupFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'individualSignupFailure',
        category: 'Auth',
        meta: {
          phoneNo: action.data.phoneNumber,
          error: JSON.parse(error.message),
        },
      }),
    );
  }
}

export function* signupCorporate(action: SignupCorporateAction): SagaIterator {
  yield put(signupRequest());
  yield put(
    sendAnalytics({
      event: 'corporateSignupRequest',
      category: 'Auth',
      meta: {
        phoneNo: action.data.phoneNumber,
      },
    }),
  );

  try {
    const phoneNumber = formartPhoneNo(action.data.phoneNumber);
    const data = {
      ...action.data,
      phoneNumber,
    };
    const { userId } = yield call(authApi.signupCorporate, data);
    yield put(signupSuccess({ userId, phoneNumber }));
    yield call(AuthService.verifyPhoneNo, userId, phoneNumber, '/signin');
    yield put(
      sendAnalytics({
        event: 'corporateSignupSuccess',
        category: 'Auth',
        meta: {},
      }),
    );
  } catch (error) {
    yield put(signupFailure(JSON.parse(error.message)));
    yield put(
      sendAnalytics({
        event: 'corporateSignupFailure',
        category: 'Auth',
        meta: {
          phoneNo: action.data.phoneNumber,
          error: JSON.parse(error.message),
        },
      }),
    );
  }
}
