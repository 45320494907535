import { normalize } from 'normalizr';
import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as api from '../copyrights-api';
import { copyrightsModel } from '../../schemas';
import { Copyright, CopyrightEntities } from '../interfaces';

import {
  fetchCopyrightsRequest,
  fetchCopyrightsFailure,
  fetchApprovedCopyrightsSuccess,
  fetchPendingCopyrightsSuccess,
  fetchRejectedCopyrightsSuccess,
  FetchCopyrightDetails,
  fetchCopyrightDetailsSuccess,
  fetchDraftCopyrightsSuccess,
} from './actions';

export function* fetchApprovedCopyrights(): SagaIterator {
  yield put(fetchCopyrightsRequest());

  try {
    const copyrights = yield call(api.fetchApprovedCopyrights);
    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;
    yield put(fetchApprovedCopyrightsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    yield put(fetchCopyrightsFailure());
  }
}

export function* fetchPendingCopyrights(): SagaIterator {
  yield put(fetchCopyrightsRequest());

  try {
    const copyrights = yield call(api.fetchPendingCopyrights);
    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;
    yield put(fetchPendingCopyrightsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    yield put(fetchCopyrightsFailure());
  }
}

export function* fetchDraftCopyrights(): SagaIterator {
  yield put(fetchCopyrightsRequest());

  try {
    const copyrights = yield call(api.fetchDraftCopyrights);
    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;
    yield put(fetchDraftCopyrightsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    yield put(fetchCopyrightsFailure());
  }
}

export function* fetchRejectedCopyrights(): SagaIterator {
  yield put(fetchCopyrightsRequest());

  try {
    const copyrights = yield call(api.fetchRejectedCopyrights);
    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;
    yield put(fetchRejectedCopyrightsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    yield put(fetchCopyrightsFailure());
  }
}

export function* fetchCopyrightDetails(action: FetchCopyrightDetails): SagaIterator {
  yield put(fetchCopyrightsRequest());

  try {
    const copyrights = yield call(api.fetchCopyrightDetails, action.copyrightId);
    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;
    yield put(fetchCopyrightDetailsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    yield put(fetchCopyrightsFailure());
  }
}
