import { combineReducers } from 'redux';
import authReducer from './auth/reducers';
import usersReducer from './users/reducers';
import profilesReducer from './profiles/reducers';
import copyrightsReducer from './copyrights/reducers';
import categoriesReducer from './categories/reducers';
import analyticsReducer from './analytics/reducers';
import transactionsReducer from './transactions/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  profiles: profilesReducer,
  copyrights: copyrightsReducer,
  categories: categoriesReducer,
  analytics: analyticsReducer,
  transactions: transactionsReducer,
});

export default rootReducer;
