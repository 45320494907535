import { normalize } from 'normalizr';
import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as api from '../copyrights-api';
import { copyrightsModel } from '../../schemas';
import { Copyright, CopyrightEntities } from '../interfaces';

import {
  fetchHistoricalCopyrightsRequest,
  fetchHistoricalCopyrightsFailure,
  fetchHistoricalCopyrightsSuccess,
  FetchHistoricalCopyrightDetails,
  fetchHistoricalCopyrightDetailsSuccess,
} from './actions';

export function* fetchHistoricalCopyrights(): SagaIterator {
  yield put(fetchHistoricalCopyrightsRequest());

  try {
    const { data: copyrights } = yield call(api.fetchHistoricalCopyrights);

    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;

    yield put(fetchHistoricalCopyrightsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    console.log(error);

    yield put(fetchHistoricalCopyrightsFailure());
  }
}

export function* fetchHistoricalCopyrightDetails(
  action: FetchHistoricalCopyrightDetails,
): SagaIterator {
  yield put(fetchHistoricalCopyrightsRequest());

  try {
    const copyrights = yield call(api.fetchHistoricalCopyrightDetails, action.copyrightId);

    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;

    yield put(fetchHistoricalCopyrightDetailsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    yield put(fetchHistoricalCopyrightsFailure());
  }
}
