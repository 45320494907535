import { types } from '../types';

export interface AmendCopyrightRequest {
  type: typeof types.AMEND_COPYRIGHT_REQUEST;
}

export interface AmendCopyrightFailure {
  type: typeof types.AMEND_COPYRIGHT_FAILURE;
  messages: Record<string, string>[];
}

export interface AmendCopyrightSuccess {
  type: typeof types.AMEND_COPYRIGHT_SUCCESS;
}

export interface AmendCopyrightAction {
  type: typeof types.AMEND_COPYRIGHT;
  requestType: string;
  copyrightId: string;
}

export const amendCopyrightRequest = () => ({
  type: types.AMEND_COPYRIGHT_REQUEST,
});

export const amendCopyrightFailure = (messages: Record<string, string>[]) => ({
  type: types.AMEND_COPYRIGHT_FAILURE,
  messages,
});

export const amendCopyrightSuccess = () => ({
  type: types.AMEND_COPYRIGHT_SUCCESS,
});

export const amendCopyright = (requestType: string, copyrightId: string): AmendCopyrightAction => {
  return {
    type: types.AMEND_COPYRIGHT,
    requestType,
    copyrightId,
  };
};

export type AmendCopyrightActions =
  | AmendCopyrightRequest
  | AmendCopyrightFailure
  | AmendCopyrightSuccess;
