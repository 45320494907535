import { normalize } from 'normalizr';
import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';

import * as api from '../copyrights-api';
import { copyrightsModel } from '../../schemas';
import { Copyright, CopyrightEntities } from '../interfaces';

import {
  searchCopyrightsRequest,
  searchCopyrightsFailure,
  searchCopyrightsSuccess,
  SearchCopyrightAction,
} from './actions';

export default function* searchCopyrights(action: SearchCopyrightAction): SagaIterator {
  yield put(searchCopyrightsRequest());

  try {
    const { copyrights } = yield call(api.searchCopyrights, action.term);

    const normalized = normalize<Copyright[], CopyrightEntities, string[]>(
      copyrights,
      copyrightsModel,
    );

    const { result: copyrightIds, entities } = normalized;

    yield put(searchCopyrightsSuccess(entities.copyrights, copyrightIds));
  } catch (error) {
    yield put(searchCopyrightsFailure());
  }
}
