import LogRocket from 'logrocket';
import { SagaIterator } from 'redux-saga';
import { put, call, takeEvery } from 'redux-saga/effects';

import { types } from './types';
import * as api from './profiles-api';
import { ProfileResponse, CurrentUser } from './interfaces';

import {
  fetchCurrentUserRequest,
  fetchCurrentUserSuccess,
  fetchCurrentUserFailure,
} from './actions';

function* fetchCurrentUser(): SagaIterator {
  yield put(fetchCurrentUserRequest());

  try {
    const { individual, corporate }: ProfileResponse = yield call(api.fetchCurrentUser);

    let profile: CurrentUser;

    if (individual) profile = individual;
    else profile = corporate;

    yield put(fetchCurrentUserSuccess(profile));

    if (process.env.NODE_ENV === 'production') {
      const phone = profile.phoneNumber;

      LogRocket.identify(profile.userId, {
        name: profile.businessName
          ? profile.businessName
          : `${profile.firstName} ${profile.lastName}`,
        phone:
          phone.substring(0, phone.length - 4).replace(/\d/g, '*') +
          phone.substring(phone.length - 4),
        isIndividual: profile.isIndividual,
      });
    }
  } catch (error) {
    yield put(fetchCurrentUserFailure());
  }
}

export default function* watchProfiles(): SagaIterator {
  yield takeEvery(types.FETCH_CURRENT_USER, fetchCurrentUser);
}
